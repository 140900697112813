import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import RateChart from '../components/ui/rate-chart-full';

import Property from '../components/ui/property';

export default ({ data, path, pageContext: { id } }) => {

  // console.log({ id: id });
  // console.log({ data: data });

  const item = data.allDataJson.edges[0].node.venda.find((item) => item.id == id) || {};

  let last = 0;

  const hist = item.hist.map((p, index) => {

    const value = parseFloat(p.rate);

    const label = (Math.abs(last - value) > 0.1) ? value.toFixed(2) : null;

    last = value;

    return { x: new Date(p.ts), y: value, label: label };
  });

//  console.log({ hist: hist });

  const rate = parseFloat(item.rate);

  const title = `${item.name} - Resgatar - Acompanhe a variação e o gráfico com o histórico da taxa de rendimento do título do Tesouro Direto.`;

  return (
    <Layout>
      <SEO title={title} description={title} lang='pt-br' />
      <div>
        <h1 className="bold text-2xl">{item.name}</h1>

        <div className="mt-8 max-w-xl">
          <Property label="Taxa de Rendimento" value={`${rate.toFixed(2)}% a.a.`} />
          <Property label="Variação" value={item.net_change} />
          <Property label="Vencimento" value={item.maturity_at} />
          <Property label="Preço unitário" value={item.str_price} />
          <Property label="Última atualização" value={item.str_ts} />
        </div>

        <h1 className="mt-12 bold text-xl text-gray-800">Gráfico do histórico da taxa de rendimento do título no periodo de 90 dias</h1>

        <RateChart data={hist} title={item.name} className="" />
      </div>
    </Layout >
  )
}
export const query = graphql`
query {
  allDataJson {
    edges {
      node {
        venda {
          name
          id
          link
          rate
          maturity_at
          str_price
          net_change
          str_ts
          hist {
            ts
            rate
          }          
        }
      }
    }
  }
  }
`